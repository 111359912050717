import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadDocumentComponent } from 'src/app/shared/components/upload-document/upload-document/upload-document.component';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { UploadedFile } from 'src/app/shared/models/types';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentService } from 'src/app/shared/services/document.service';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { Router } from '@angular/router';
import { Document } from 'src/app/shared/models/document.model';

@Component({
  selector: 'app-upload-document-field',
  templateUrl: './upload-document-field.component.html',
  styleUrls: ['./upload-document-field.component.scss']
})
export class UploadDocumentFieldComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() documentControlName: string;
  @Input() documentTypes: DocumentType[]; // document types displayed in DDL
  @Input() fieldName: string; // label + placeholder
  @Input() popUpPurpose: string;
  @Input() popUpTitle: string;
  @Input() documentParentType: string; // what module uploaded this document e.g shipment
  @Input() documentParentId?: string; // id of who uploaded it e.g: shipment with id=> 938872d0-299d-4615-9458-08dc12a96a9f
  // add any required fields in the future
  @Input() uploadedFile: Document = null;
  uploadingFile: UploadedFile = null;
  @Input() isRequired: boolean = true;
  @Input() placeholder: string;

  constructor(
    private dialog: MatDialog,
    private formsHelperService: FormsHelperService,
    private documentService: DocumentService,
    private fileViewerService: FileViewerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.isRequired) {
      this.formsHelperService.addRequiredValidator(
        this.form,
        this.documentControlName
      );
    }

    this.documentService.fileUploadingEmitter.subscribe(res => {
      if (this.uploadedFile?.id === null || this.uploadedFile === null) {
        this.addUploadingDocument(res.data, res.uploadedFile, res.isError);
      }
    });
    if (this.isEditRoute) {
      this.editView();
    }
  }
  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  ngOnDestroy(): void {
    this.deleteUploadedFile();
    this.formsHelperService.clearValidator(this.form, this.documentControlName);
  }

  uploadFile(): void {
    const dialogRef = this.dialog.open(UploadDocumentComponent, {
      hasBackdrop: true,
      width: '600px',
      maxHeight: '550px',
      data: {
        popUpPurpose: this.popUpPurpose,
        parentType: this.documentParentType,
        documentTypes: this.documentTypes,
        formTitle: this.popUpTitle,
        parentTypeId: this.documentParentId
      }
    });
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.form,
      formControlName
    );
  }

  addUploadingDocument(
    uploadedDocument: Document,
    uploadingFile,
    isError: boolean
  ): void {
    if (uploadedDocument) {
      this.uploadedFile = uploadedDocument;
      this.uploadingFile.isUploading = false;
      this.uploadingFile.isUploaded = true;
      this.uploadingFile.isCanceled = false;
      this.form?.get(this.documentControlName)?.setValue({
        documentSqlId: this.uploadedFile?.id,
        path: this.uploadedFile?.path,
        fileName: this.uploadedFile?.fileName,
        description: this.uploadedFile?.description
      });
    } else if (uploadingFile && !isError) {
      this.uploadingFile = uploadingFile;
    }
  }

  viewFile(path): void {
    if (this.uploadedFile) {
      this.fileViewerService.view(path);
    }
  }

  cancelUploadingFile(): void {
    this.uploadingFile.isCanceled = true;
    this.uploadingFile.isUploaded = false;
    this.uploadingFile.isUploading = false;

    this.documentService.cancelFileUploadingEmitter.emit({
      val: false,
      uploadedFile: this.uploadingFile
    });
  }

  deleteUploadedFile(): void {
    if (this.uploadingFile?.isCanceled) {
      this.resetUploadedFile();
    }
    if (this.uploadedFile && !this.isEditRoute) {
      this.documentService
        .deleteDocument(this.uploadingFile?.id)
        .subscribe(_ => {
          this.resetUploadedFile();
        });
    } else {
      this.resetUploadedFile();
    }
  }

  resetUploadedFile(): void {
    this.uploadedFile = null;
    this.uploadingFile = null;
    this.form?.get(this.documentControlName)?.setValue(null);
  }

  editView() {
    if (this.form?.get('invoiceDocument').value != null) {
      const invoiceForm = this.form?.get('invoiceDocument').value;
      this.uploadingFile = {
        fileName: invoiceForm?.fileName,
        id: invoiceForm?.documentSqlId,
        isCanceled: false,
        isUploaded: true,
        isUploading: false,
        note: invoiceForm?.description,
        percentageValue: 100,
        typeName: 'Commercial Invoice'
      };
    }
  }

  reuploadFile() {
    this.documentService.uploadingFile(false, this.uploadingFile, null, true);
  }
}
