<div class="row ocean-freight-container">
  <div class="col-2 title">
    {{ sectionTitle }}
  </div>
  <div class="col-10 pl-0">
    <div class="row">
      <div class="col-4 field field-label">Cargo Type</div>
      <div class="col-4 field field-label" *ngIf="isAirOCeanLCLLoadType">
        Packing Details
      </div>
    </div>
    <div class="row">
      <div class="col-4 field field-value">{{ oceanFreightDescription }}</div>
      <div class="col-4 field field-value" *ngIf="isAirOCeanLCLLoadType">
        {{ packingDetailsDescription }}
      </div>
    </div>

    <div *ngIf="isDisplayContainersDetails" class="containers-section">
      <div class="container-details" *ngIf="has20Container">
        <div class="row">
          <div class="col-2 field-label field">20’ Container</div>
          <div class="col-2 field-label field">Unit Weight (kg)</div>
          <div class="col-2 field-label field">Quantity</div>
        </div>
        <div
          class="row"
          *ngFor="let container20 of containers20; let i = index"
        >
          <div class="col-2 field field-value  ">
            {{ i + 1 }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container20.weight || "-" }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container20.quantity }}
          </div>
        </div>
      </div>

      <div class="container-details" *ngIf="has40Container">
        <div class="row ">
          <div class="col-2 field-label field">40’ Container</div>
          <div class="col-2 field-label field">Unit Weight (kg)</div>
          <div class="col-2 field-label field">Quantity</div>
        </div>
        <div
          class="row"
          *ngFor="let container40 of containers40; let i = index"
        >
          <div class="col-2 field field-value  ">
            {{ i + 1 }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container40.weight || "-" }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container40.quantity }}
          </div>
        </div>
      </div>

      <div class="container-details" *ngIf="has40HcContainer">
        <div class="row ">
          <div class="col-2 field-label field">40’ HC Container</div>
          <div class="col-2 field-label field">Unit Weight (kg)</div>
          <div class="col-2 field-label field">Quantity</div>
        </div>
        <div
          class="row"
          *ngFor="let container40 of containers40Hc; let i = index"
        >
          <div class="col-2 field field-value  ">
            {{ i + 1 }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container40.weight || "-" }}
          </div>
          <div class="col-2 field field-value  ">
            {{ container40.quantity }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
