// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://platform.freepl.com', // Change this to the address of your backend API if different from frontend address
  tokenUrl: null, // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
  apiUrl: 'https://platform.freepl.com/api/',
  loginUrl: '/login',
  googleClientId:
    '439188608778-n9a4ulafkm5s8f2k4p8afglcrvohht3h.apps.googleusercontent.com',
  facebookClientId: '2356966407766363',
  googleMapsApiKey: 'AIzaSyBtGIh7wWBaHGTWZ5z1-6ucfeJ4wBvn8no'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
