<div class="cancel-shipment-container">
  <svg-icon name="removeDocument"></svg-icon>
  <div class="spacing-between-icon-and-title"></div>
  <app-page-header [title]="title + ' Cancellation'"></app-page-header>
  <div class="spacing-between-icon-and-title"></div>

  <app-error-badge
    [message]="errorMesage"
    *ngIf="reasonError"
  ></app-error-badge>

  <div class="spacing-between-icon-and-title"></div>

  <div class="cancel-shipment-warning-paragraph">
    You are about to cancel this {{ title | lowercase }}. Instead, you can
    contact your account manager to discuss any concern.
  </div>
  <div class="cancel-shipment-note-title">
    Why would you like to cancel this {{ title | lowercase }}?
    <span class="required-symbol">*</span>
  </div>
  <textarea
    [(ngModel)]="reason"
    type="text"
    placeholder="Add note"
    class="cancel-reason"
    [class]="reasonError ? 'error' : ''"
    maxlength="180"
  >
  </textarea>

  <div class="form-buttons row">
    <div class="col-6">
      <button class="keep removal-form-buttons" (click)="confirm(false)">
        Keep {{ title }}
      </button>
    </div>
    <div class="col-6">
      <button
        class="cancel removal-form-buttons"
        (click)="confirm(true)"
        [disabled]="isLoading"
      >
        Cancel {{ title }}

        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
      </button>
      <div class="confirmation-warning">
        <svg-icon [name]="'mark!'"></svg-icon>
        <div class="confirmation-warning-text">
          This action cannot be undone.
        </div>
      </div>
    </div>
  </div>
</div>
