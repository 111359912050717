<div class="w-100">
  <div class="devider"></div>
  <div class="row w-100">
    <div class="title col-2">
      {{ hasDomesticService ? "Trucking details" : "Trucking" }}
    </div>
    <div
      class="itemsCon col-10"
      *ngIf="hasDomesticService; else hasNoDomesticType"
    >
      <div class="row">
        <div class="detailsCon col-12">
          <div class="detailsTitle">Route</div>
          <div class="detailsText">
            {{ domesticTruckingRoute }}
          </div>
        </div>

        <div class="detailsCon col-2 mt-3 pt-3">
          <div class="detailsTitle">Load Type</div>
          <div class="detailsText">
            {{ domesticTruckingInformation?.loadType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 mt-3 pt-3">
          <div class="detailsTitle">Truck Type</div>
          <div class="detailsText">
            {{ domesticTruckingInformation?.truckType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 mt-3 pt-3">
          <div class="detailsTitle">Truck Subtype</div>
          <div class="detailsText">
            {{ domesticTruckingInformation?.truckSubType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 mt-3 pt-3">
          <div class="detailsTitle">Quantity</div>
          <div class="detailsText">
            {{ domesticTruckingInformation?.quantity || "-" }}
          </div>
        </div>

        <div class="detailsCon col-12 mt-3 pt-3">
          <div class="detailsTitle">Special Requests</div>
          <div class="detailsText">
            {{ domesticTruckingInformation?.specialRequirements || "-" }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLandStandaloneService">
      <div
        class="row"
        *ngIf="quoteLandTruckingDetails?.landTruckingInformation.length > 0"
      >
        <div class="detailsCon col-2 pt-1">
          <div class="detailsTitle">Truck</div>
        </div>

        <div class="detailsCon col-2 pt-1">
          <div class="detailsTitle">Truck Load</div>
        </div>

        <div class="detailsCon col-2 pt-1">
          <div class="detailsTitle">Truck Type</div>
        </div>

        <div class="detailsCon col-2 pt-1">
          <div class="detailsTitle">Truck Subtype</div>
        </div>

        <div class="detailsCon col-2 pt-1">
          <div class="detailsTitle">Quantity</div>
        </div>
      </div>
      <div
        class="row"
        *ngFor="
          let item of quoteLandTruckingDetails?.landTruckingInformation;
          let i = index
        "
      >
        <div class="detailsCon col-2 pt-3">
          <div class="detailsText">
            {{ i + 1 }}
          </div>
        </div>

        <div class="detailsCon col-2 pt-3">
          <div class="detailsText">
            {{ item?.loadType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 pt-3">
          <div class="detailsText">
            {{ item?.truckType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 pt-3">
          <div class="detailsText">
            {{ item?.truckSubType || "-" }}
          </div>
        </div>

        <div class="detailsCon col-2 pt-3">
          <div class="detailsText">
            {{ item?.quantity || "-" }}
          </div>
        </div>
      </div>
      <div class="detailsCon col-12 mt-3 pt-3 pl-0">
        <div class="detailsTitle">Special Requirements</div>
        <div class="detailsText">
          {{ quoteLandTruckingDetails?.specialRequirements || "-" }}
        </div>
      </div>
    </div>
    <ng-template #hasNoDomesticType>
      <div class="row" *ngIf="!isLandStandaloneService">
        <div class="detailsCon col-12">
          <div class="detailsTitle">Truck Type</div>
          <div class="detailsText">
            To be provided by account manager
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
