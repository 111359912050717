import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  AfterViewInit,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationError,
  RouterEvent,
  NavigationEnd,
  NavigationCancel
} from '@angular/router';
import {
  ToastaService,
  ToastaConfig,
  ToastOptions,
  ToastData
} from 'ngx-toasta';
import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  AlertService,
  AlertDialog,
  DialogType,
  AlertCommand,
  MessageSeverity
} from './shared/services/alert.service';
import { NotificationService } from './shared/services/notification.service';
import { AppTranslationService } from './shared/services/app-translation.service';
import { LocalStoreManager } from './shared/services/local-store-manager.service';
import { AppTitleService } from './shared/services/app-title.service';
import { AuthService } from './shared/services/auth.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { CompanyService } from './company/services/company.service';
import { LoginComponent } from './employee-profile/components/login/login.component';
import { IdleService } from './shared/services/idle-service.service';

const alertify: any = require('./assets/scripts/alertify.js');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isAppLoaded: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = 'FreePl';

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;
  loading = true;

  constructor(
    private idleService: IdleService,
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private companyService: CompanyService,
    public translationService: AppTranslationService,
    public configurations: ConfigurationService,
    public router: Router,
    private cdr: ChangeDetectorRef
  ) {
    storageManager.initialiseStorageSyncListener();

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = false;

    this.appTitleService.appName = this.appTitle;
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
      this.cdr.detectChanges();
    });
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () =>
              this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }

  onLoginModalShown() {
    this.alertService.showStickyMessage(
      'Session Expired',
      'Your Session has expired. Please log in again',
      MessageSeverity.info
    );
  }

  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showStickyMessage(
        'Session Expired',
        'Your Session has expired. Please log in again to renew your session',
        MessageSeverity.warn
      );
    }
  }

  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }

  ngOnInit() {
    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => (this.isAppLoaded = true), 500);
    setTimeout(() => (this.removePrebootScreen = true), 1000);

    this.alertService
      .getDialogEvent()
      .subscribe(alert => this.showDialog(alert));
    this.alertService
      .getMessageEvent()
      .subscribe(message => this.showToast(message));

    this.authService.reLoginDelegate = () => (this.shouldShowLoginModal = true);

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      if (isLoggedIn && !this.authService.isAdmin) {
        // this.initNotificationsLoading();
      } else {
        this.unsubscribeNotifications();
        this.authService.gotoDefaultPage();
      }

      setTimeout(() => {
        if (!isLoggedIn) {
          this.alertService.showMessage(
            'Session Ended!',
            '',
            MessageSeverity.default
          );
        }
      }, 500);
    });

    this.companyService.customerCompanySwitched.subscribe(data => {
      if (data['isNavigateToDashboard'] == true) {
        this.router.navigate([`/company/${data['companyId']}/dashboard`]);
      } else {
        this.alertService.success(
          'Current customer profile changed based on the viewed shipment.'
        );
      }
    });
    this.startWatch();
  }

  ngOnDestroy() {
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }

  showDialog(dialog: AlertDialog) {
    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify.confirm(dialog.message, e => {
          if (e) {
            dialog.okCallback();
          } else {
            if (dialog.cancelCallback) {
              dialog.cancelCallback();
            }
          }
        });

        break;
      case DialogType.prompt:
        alertify.prompt(
          dialog.message,
          (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          },
          dialog.defaultValue
        );

        break;
    }
  }

  showToast(alert: AlertCommand) {
    if (alert?.message?.summary == 'The username/password couple is invalid.') {
      return;
    }

    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail
    };

    if (alert.operation === 'add_sticky') {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }

    switch (alert.message.severity) {
      case MessageSeverity.default:
        this.toastaService.default(toastOptions);
        break;
      case MessageSeverity.info:
        this.toastaService.info(toastOptions);
        break;
      case MessageSeverity.success:
        this.toastaService.success(toastOptions);
        break;
      case MessageSeverity.error:
        this.toastaService.error(toastOptions);
        break;
      case MessageSeverity.warn:
        this.toastaService.warning(toastOptions);
        break;
      case MessageSeverity.wait:
        this.toastaService.wait(toastOptions);
        break;
    }
  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get fullName(): string {
    return this.authService.currentUser
      ? this.authService.currentUser.fullName
      : '';
  }

  get isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get canSeeAdminSide(): boolean {
    return this.authService.canSeeAdminSide;
  }

  get hasAccessToNavbar(): boolean {
    return this.authService.currentUser.email == 'y.amrousy.am@gmail.com' ;
  }

  startWatch() {
    this.idleService.startIdleWatch;
  }
}
