import { Component, Input, OnInit } from '@angular/core';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
@Component({
  selector: 'app-product-registration-card',
  templateUrl: './product-registration-card.component.html',
  styleUrls: ['./product-registration-card.component.scss']
})
export class ProductRegistrationCardComponent implements OnInit {
  @Input() isBlueArrowHidden: boolean = false;
  @Input() productRegistration: ProductRegistration;
  @Input() isAllSelected: boolean;
  @Input() isInDashboard: boolean = false;
  constructor(
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productRegistrationService: ProductRegistrationService
  ) {}

  ngOnInit(): void {}

  get productRegistrationNumberString(): string {
    return this.productRegistrationHelperService.getProductRegistrationNumberString(
      this.productRegistration
    );
  }

  get submittedAt(): Date {
    return this.productRegistrationHelperService.getSubmittedAt(
      this.productRegistration
    );
  }

  get updatedStatusDate(): Date {
    return this.productRegistrationHelperService.getUpdatedStatusDate(
      this.productRegistration
    );
  }

  get currentStatus(): string {
    return this.productRegistrationHelperService.getCurrentStatus(
      this.productRegistration
    );
  }

  get productName(): string {
    return this.productRegistrationHelperService.getProductDescription(
      this.productRegistration
    );
  }

  get acceptanceNumber(): string {
    return this.productRegistrationHelperService.getAcceptanceNumber(
      this.productRegistration
    );
  }
  get registrationLicenseNumber(): string {
    return this.productRegistrationHelperService.getRegistrationLicenseNumber(
      this.productRegistration
    );
  }
  get hasPendingActions(): boolean {
    return this.productRegistrationHelperService.getHasPendingActions(
      this.productRegistration
    );
  }

  get isProductInSelectedProductsList(): boolean {
    const isSelected = this.productRegistrationService.isProductInSelectedProductsList(
      this.productRegistration.productRegistrationNumberString
    );

    return this.isAllSelected ? !isSelected : isSelected;
  }

  selectOrDeselectProduct(event): void {
    event.stopPropagation();
    const isChecked: boolean = event.target.checked;
    if (isChecked) {
      if (!this.isAllSelected) {
        this.productRegistrationService.addToListOfSelectedProducts(
          this.productRegistration.productRegistrationNumberString
        );
      } else {
        this.productRegistrationService.removeFromListOfSelectedProducts(
          this.productRegistration.productRegistrationNumberString
        );
      }
    } else {
      if (!this.isAllSelected) {
        this.productRegistrationService.removeFromListOfSelectedProducts(
          this.productRegistration.productRegistrationNumberString
        );
      } else {
        this.productRegistrationService.addToListOfSelectedProducts(
          this.productRegistration.productRegistrationNumberString
        );
      }
    }
  }
}
