import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';

@Component({
  selector: 'app-cancel-entity',
  templateUrl: './cancel-entity.component.html',
  styleUrls: ['./cancel-entity.component.scss']
})
export class CancelEntityComponent implements OnInit {
  entityName: string;
  reason: string;
  isLoading: boolean;
  reasonError: boolean;
  minLength: number = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CancelEntityComponent>
  ) {}

  ngOnInit(): void {
    this.entityName = this.data['entityName'];
  }

  confirm(isCancel: boolean) {
    if (this.isLoading) {
      return;
    }

    if (isCancel) {
      if (!this.reason || this.reason?.trim()?.length < this.minLength) {
        this.reasonError = true;
        return;
      }
    }

    this.dialogRef.close(this.reason);
  }

  get isProductRegistrationRequestEntity(): boolean {
    return this.entityName == EntityType.Registration;
  }

  get errorMesage(): string {
    return `Please enter at least 5 characters to submit.`;
  }

  get isApprovalEntity(): boolean {
    return this.entityName == EntityType.Approval;
  }

  get title(): string {
    if (this.isProductRegistrationRequestEntity || this.isApprovalEntity) {
      return 'Request';
    } else {
      return 'Shipment';
    }
  }
}
