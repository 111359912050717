<div
  [classList]="'field-bg  p-4 position-relative ' + classList"
  id="wrapper"
  [ngClass]="{ 'validation-err': hasError }"
>
  <div class="d-flex align-items-start justify-content-between ">
    <div><img src="{{ iconPath }}" alt="" srcset="" /></div>
    <span class="mx-1"></span>
    <div class="d-flex flex-column flex-grow-1">
      <span class="font-weight-600 text-16 mb-2">{{ title }}</span>
      <span class="description">{{ description }}</span>
      <ng-content></ng-content>
    </div>
  </div>
  <input [formControl]="control" type="checkbox" />
  <div class="circle-container">
    <svg-icon class="check-icon" [name]="'white-check'"></svg-icon>
  </div>
</div>
