<div class="accordionCon">
  <div class="titleCon">
    <div
      class="title d-flex align-items-center"
      [ngStyle]="{ color: titleColor }"
    >
      <div class="icon">
        <ng-template [ngTemplateOutlet]="icon"></ng-template>
      </div>
      <span name="lbl_title" class="title-text">
        {{ title }}
      </span>
    </div>
    <div class="line"></div>
    <button class="action" [ngClass]="{ active: active }" (click)="toggle()">
      <svg-icon name="arrow-down"></svg-icon> 
    </button>
  </div>
  <div class="body" [ngClass]="{ active: active }">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
</div>


