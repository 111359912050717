import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/shared/models/role.model';
import { AccountService } from 'src/app/shared/services/account.service';
import {
  FormArray,
  FormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { RoleAndPermissionsDTO } from '../../models/role-and-permissions-dto';
import { CategoryAndPermissions } from '../../models/category-and-permissions';
import { Permission } from 'src/app/shared/models/permission.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {
  roleAndPermissions: RoleAndPermissionsDTO;
  categoryPermissions: CategoryAndPermissions[];
  selectedPermissions: Permission[];
  permissionForm: UntypedFormGroup;
  isSaving: boolean;
  isFailedToSave: boolean;
  isSearching: boolean;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      if (this.isEditRoute) {
        this.roleAndPermissions = data['roleAndPermissions'];
      }
      this.categoryPermissions = data['categoryPermissions'];
    });

    this.createForm();
  }

  createForm() {
    this.permissionForm = this.fb.group({
      name: [
        this.roleAndPermissions?.role?.name,
        [Validators.required, Validators.minLength(2), Validators.maxLength(30)]
      ],
      description: [
        this.roleAndPermissions?.role?.description,
        [Validators.maxLength(100)]
      ],
      permissionGroups: this.fb.array([])
    });

    this.categoryPermissions.forEach(categoryPermission => {
      let permissions = this.roleAndPermissions?.permissions?.filter(
        permission =>
          permission?.permissionCategoryId ===
          categoryPermission?.permissionCategory?.id
      );
      this.addPermissionGroup(permissions);
    });
  }

  addPermissionGroup(permissions: Permission[]) {
    let control = this.fb.control(permissions);
    (this.permissionForm.get('permissionGroups') as FormArray).push(control);
  }

  getPermissionGroupControl(index: number) {
    return (this.permissionForm.get('permissionGroups') as FormArray).at(index);
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  addNewRole(newRole: Role) {
    this.accountService.newRole(newRole).subscribe(res => {
      this.handleSaveResponse();
      this.isSaving = false;
    });
  }

  editRole(editedRole: Role) {
    this.accountService.updateRole(editedRole).subscribe(res => {
      this.handleSaveResponse();
      this.isSaving = false;
    });
  }

  save() {
    this.permissionForm.markAllAsTouched();

    if (this.permissionForm.invalid || this.isSaving) {
      this.isFailedToSave = true;
      return;
    }

    this.isSaving = true;
    this.isFailedToSave = false;

    if (this.isEditRoute) {
      this.roleAndPermissions.role.name = this.permissionForm?.value?.name;
      this.roleAndPermissions.role.description = this.permissionForm?.value?.description;
      this.roleAndPermissions.role.permissions = this.mergedPermissions();
      this.editRole(this.roleAndPermissions.role);
    } else {
      let role: Role = {
        name: this.permissionForm?.value?.name,
        description: this.permissionForm?.value?.description,
        permissions: this.mergedPermissions()
      };

      this.addNewRole(role);
    }
  }

  mergedPermissions(): Permission[] {
    let selectedPermissions = []
      .concat(...this.permissionForm?.value?.permissionGroups)
      .filter(item => item != null);
    return selectedPermissions;
  }

  handleSaveResponse(): void {
    this.alertService.success(
      this.isEditRoute
        ? 'Role updated successfully!'
        : 'Role added successfully!'
    );
    this.router.navigate(['/settings'], { fragment: 'roles' });
  }

  get errorMesage(): string {
    return `Required fields are either left empty or filled incorrectly
            . Check all fields highlighted in red and try again.`;
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formHelperService?.isInvalidFormControl(
      this.permissionForm,
      formControlName
    );
  }

  get selectedPermissionsCount(): number[] {
    let permissions = this.permissionForm.get('permissionGroups')?.value;
    return this.categoryPermissions.map(
      (_, index) => permissions[index]?.length || 0
    );
  }

  onPermissionsFieldFocus() {
    this.isSearching = true;
  }

  onPermissionsFieldBlur() {
    this.isSearching = false;
  }
}
